
import VueRouter from 'vue-router'
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc'
import {Vue} from "vue-property-decorator"
import store from '@/store'
import Dashboard from "@/views/Dashboard.vue";
import Logout from "@/views/Logout.vue";
import { hasRole } from "@/mixins/AuthenticationMixin";
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      pageTitle: 'Dashboard',
      menuActive: '/'
    }
  }, {
    path: '/statistics',
    name: 'statistics',
    meta: {
      pageTitle: 'Statistieken',
      menuActive: '/'
    },
    component: () => import(/* webpackChunkName: "statistics" */ '../views/Statistics.vue'),
  }, {
    path: '/inbounds',
    name: 'inbound_overview',
    meta: {
      pageTitle: 'Inslag Overzicht',
      menuActive: '/inbounds'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "inbound" */ '../views/InboundOverview.vue'),
    props: (route) => (
      {
        currentPage: parseInt(route.query.page || 1)
      }
    )
  }, {
    path: '/inbounds/create',
    name: 'inbound_create',
    meta: {
      pageTitle: 'Nieuwe Inslag',
      menuActive: '/inbounds'
    },
    component: () => import(/* webpackChunkName: "inbound" */ '../views/InboundDetail.vue')
  }, {
    path: '/inbounds_return/create',
    name: 'inbound_return_create',
    meta: {
      pageTitle: 'Nieuwe Retour Inslag',
      menuActive: '/inbounds',
      return: true,
    },
    component: () => import(/* webpackChunkName: "inbound" */ '../views/InboundDetail.vue')
  }, {
    path: '/inbounds/:id',
    name: 'inbound_detail',
    meta: {
      pageTitle: 'Inslag Detail',
      menuActive: '/inbounds'
    },
    component: () => import(/* webpackChunkName: "inbound" */ '../views/InboundDetail.vue'),
  }, {
        path: '/inbound/stats',
        name: 'inbound_stats',
        meta: {
            pageTitle: 'Inslag Statistieken'
        },
        component: () => import(/* webpackChunkName: "inbound" */ '../views/InboundStats.vue')
  }, {
        path: '/inbound-return/stats',
        name: 'inbound_return_stats',
        meta: {
            pageTitle: 'Inslag Retour Statistieken'
        },
        component: () => import(/* webpackChunkName: "inbound" */ '../views/InboundReturnStats.vue')
  }, {
    path: '/inbound_returns/:id',
    name: 'inbound_return_detail',
    meta: {
      pageTitle: 'Inslag Retour Detail',
      menuActive: '/inbounds',
      return: true,
    },
    component: () => import(/* webpackChunkName: "inbound" */ '../views/InboundDetail.vue'),
  },  {
    path: '/inbound-transports',
    name: 'inbound_transport_overview',
    meta: {
      pageTitle: 'Inslag Transport Overzicht',
      menuActive: '/inbound-transports'
    },
    component: () => import(/* webpackChunkName: "inbound_transport" */ '../views/InboundTransportOverview.vue'),
  }, {
    path: '/inbound-transports/:id',
    name: 'inbound_transport_detail',
    meta: {
      pageTitle: 'Inslag Transport Detail',
      menuActive: '/inbound-transports'
    },
    component: () => import(/* webpackChunkName: "inbound_transport" */ '../views/InboundTransportDetail.vue'),
  }, {
        path: '/inbound-transport/stats',
        name: 'inbound_transports_stats',
        meta: {
            pageTitle: 'Inslag Transport Statistieken'
        },
        component: () => import(/* webpackChunkName: "inbound_transport" */ '../views/InboundTransportStats.vue')
  }, {
    path: '/products',
    name: 'product_overview',
    meta: {
      pageTitle: 'Producten Overzicht',
      menuActive: '/products'
    },
    component: () => import(/* webpackChunkName: "products" */ '../views/ProductOverview.vue')
  }, {
    path: '/products/:id',
    name: 'product_detail',
    meta: {
      pageTitle: 'Product',
      menuActive: '/products'
    },
    component: () => import(/* webpackChunkName: "outbound" */ '../views/ProductDetail.vue'),
  }, {
    path: '/product-mutation-detail',
    name: 'product_mutation_detail',
    meta: {
      pageTitle: 'Product Mutatie Detail',
      isGranted: hasRole('WAREHOUSE_MUTATION_DETAIL'),
    },
    component: () => import(/* webpackChunkName: "product_mutation_detail" */ '../views/ProductMutationDetail.vue')
  }, {
    path: '/pick-lists',
    name: 'pick_list_overview',
    meta: {
      pageTitle: 'Producten Picken Overzicht',
      menuActive: '/pick-lists'
    },
    component: () => import(/* webpackChunkName: "product_picking" */ '../views/PickListOverview.vue')
  }, {
        path: '/to-order-pick',
        name: 'to_order_pick_overview',
        meta: {
            pageTitle: 'Naar Order Pick Overzicht',
            menuActive: '/to-order-pick'
        },
        component: () => import(/* webpackChunkName: "product_picking" */ '../views/ToOrderPickOverview.vue')
    }, {
    path: '/pick-lists/:id',
    name: 'pick_list_detail',
    meta: {
      pageTitle: 'Producten Picken Detail',
      menuActive: '/pick-lists'
    },
    component: () => import(/* webpackChunkName: "product_picking" */ '../views/PickListDetail.vue')
  }, {
        path: '/pick-list/pick/stats',
        name: 'pick_list_pick_stats',
        meta: {
            pageTitle: 'Pick Lijst Picking Statistieken'
        },
        component: () => import(/* webpackChunkName: "product_picking" */ '../views/PickListPickStats.vue')
  }, {
        path: '/pick-list/distribute/stats',
        name: 'pick_list_distribute_stats',
        meta: {
            pageTitle: 'Pick Lijst Distribute Statistieken'
        },
        component: () => import(/* webpackChunkName: "product_picking" */ '../views/PickListDistributeStats.vue')
  }, {
    path: '/package-table',
    name: 'package_table_overview',
    meta: {
      pageTitle: 'Orders Verzenden',
      menuActive: '/package-table'
    },
    component: () => import(/* webpackChunkName: "package_table" */ '../views/PackageTableOverview.vue')
  }, {
    path: '/package-table/:id',
    name: 'package_table_detail',
    meta: {
      pageTitle: 'Order Verzenden',
      menuActive: '/package-table'
    },
    component: () => import(/* webpackChunkName: "package_table" */ '../views/PackageTableDetail.vue')
  }, {
    path: '/outbounds',
    name: 'outbound_overview',
    meta: {
      pageTitle: 'Orders',
      menuActive: '/outbounds'
    },
    component: () => import(/* webpackChunkName: "outbound" */ '../views/OutboundOverview.vue')
  }, {
    path: '/outbounds/:id',
    name: 'outbound_detail',
    meta: {
      pageTitle: 'Order',
      menuActive: '/outbounds'
    },
    component: () => import(/* webpackChunkName: "outbound" */ '../views/OutboundDetail.vue'),
  }, {
    path: '/outbound/completed-stats',
    name: 'outbound_completed_stats',
    meta: {
        pageTitle: 'Orders Verzenden Statistieken'
    },
    component: () => import(/* webpackChunkName: "outbound" */ '../views/OutboundCompletedStats.vue')
  }, {
    path: '/stocks',
    name: 'stock_overview',
    meta: {
      pageTitle: 'Voorraad Overzicht',
      menuActive: '/stocks'
    },
    component: () => import(/* webpackChunkName: "stock" */ '../views/StockOverview.vue')
  }, {
    path: '/stock-multiple',
    name: 'stock_multiple_overview',
    meta: {
      pageTitle: 'Voorraad Meerdere Locaties',
    },
    component: () => import(/* webpackChunkName: "stockMultiple" */ '../views/StockMultipleOverviewNew.vue')
  }, {
        path: '/double-stock-locations',
        name: 'double_stock_locations',
        meta: {
            pageTitle: 'Dubbele Voorraad Locaties',
        },
        component: () => import(/* webpackChunkName: "stockMultiple" */ '../views/DoubleStockLocations.vue')
  },  {
    path: '/stock-balance',
    name: 'stock_balance_overview',
    meta: {
      pageTitle: 'Stock Balans Overzicht',
      stockBalance: true,
    },
    component: () => import(/* webpackChunkName: "stockBalance" */ '../views/BalanceOverview.vue')
  }, {
    path: '/stock-balance/:row',
    name: 'stock_balance_row',
    meta: {
      pageTitle: 'Stock Balansen',
      stockBalance: true,
    },
    component: () => import(/* webpackChunkName: "stockBalance" */ '../views/StockBalance.vue')
  }, {
    path: '/location-balance',
    name: 'location_balance_overview',
    meta: {
      pageTitle: 'Locatie Balans Overzicht',
      stockBalance: false,
    },
    component: () => import(/* webpackChunkName: "stockBalance" */ '../views/BalanceOverview.vue')
  }, {
    path: '/location-balance/:row',
    name: 'location_balance_row',
    meta: {
      pageTitle: 'Locatie Balansen',
      stockBalance: false,
    },
    component: () => import(/* webpackChunkName: "stockBalance" */ '../views/StockBalance.vue')
  }, {
    path: '/disabled-locations',
    name: 'disabled_locations_overview',
    meta: {
      pageTitle: 'Uitgeschakelde Locaties',
      menuActive: '/disabled-locations'
    },
    component: () => import(/* webpackChunkName: "disabledLocations" */ '../views/DisabledLocationsOverview.vue')
  }, {
        path: '/lost-on-cart',
        name: 'lost_on_cart_overview',
        meta: {
            pageTitle: 'Kwijt op Kar Overzicht',
            menuActive: '/lost-on-cart'
        },
        component: () => import(/* webpackChunkName: "lostOnCart" */ '../views/LostOnCartOverview.vue')
  }, {
    path: '/locations',
    name: 'location_overview',
    meta: {
      pageTitle: 'Locatie Overzicht',
      menuActive: '/locations',
      isGranted: hasRole('WAREHOUSE_STOCK_TRANSFER')
    },
    component: () => import(/* webpackChunkName: "location" */ '../views/LocationOverview.vue')
  }, {
    path: '/available-locations',
    name: 'available_locations_overview',
    meta: {
      pageTitle: 'Beschikbare Locaties Overzicht',
      menuActive: '/available-locations'
    },
    component: () => import(/* webpackChunkName: "availableLocations" */ '../views/AvailableLocationsOverview.vue')
  }, {
    path: '/locations/:id',
    name: 'location_detail',
    meta: {
      pageTitle: 'Location Detail',
    },
    component: () => import(/* webpackChunkName: "location" */ '../views/LocationDetail.vue')
  }, {
    path: '/carts-with-stock',
    name: 'cart_with_stock_overview',
    meta: {
        pageTitle: 'Kar Voorraad Overzicht',
        menuActive: '/carts-with-stock',
        isGranted: hasRole('WAREHOUSE_STOCK_TRANSFER')
    },
    component: () => import(/* webpackChunkName: "location" */ '../views/CartWithStockOverview.vue')
  }, {
    path: '/stock-transfers',
    name: 'stock_transfer',
    meta: {
      pageTitle: 'Stock Transfer',
      menuActive: '/stock-transfers',
      isGranted: hasRole('WAREHOUSE_STOCK_TRANSFER')
    },
    component: () => import(/* webpackChunkName: "stock_transfer" */ '../views/StockTransfer.vue')
  }, {
    path: '/distributors',
    name: 'distributor_overview',
    meta: {
      pageTitle: 'Vervoerder Overzicht',
      menuActive: '/distributors',
    },
    component: () => import(/* webpackChunkName: "distributor" */ '../views/DistributorOverview.vue')
  }, {
    path: '/printer/overview',
    name: 'printer_overview',
    meta: {
        pageTitle: 'Printer Overview',
        menuActive: '/printer'
    },
    component: () => import(/* webpackChunkName: "printer" */ '../views/PrinterOverview.vue')
  }, {
    path: '/printer/:name',
    name: 'printer_detail',
    meta: {
        pageTitle: 'Printer Detail',
        menuActive: '/printer'
    },
    component: () => import(/* webpackChunkName: "printer" */ '../views/PrinterDetail.vue'),
  }, {
        path: '/job/overview',
        name: 'job_overview',
        meta: {
            pageTitle: 'Job Overview',
            menuActive: '/job'
        },
        component: () => import(/* webpackChunkName: "job" */ '../views/JobOverview.vue')
  }, {
        path: '/packing-material/overview',
        name: 'packing_material_overview',
        meta: {
            pageTitle: 'Packing Material Overview',
            menuActive: '/packing-material'
        },
        component: () => import(/* webpackChunkName: "packing-material" */ '../views/PackingMaterialOverview.vue')
  },{
    path: '/oidc-callback', // Needs to match redirectUri (redirect_uri if you use snake case) in you oidcSettings
    name: 'oidcCallback',
    component: () => import(/* webpackChunkName: "oidc" */ '../views/OidcCallback.vue')
  }, {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: {
      pageTitle: 'Uitloggen'
    },
  }, {
    path: '/monitor/outbounds',
    name: 'monitor_outbounds',
    meta: {
        pageTitle: 'Verzend Statistieken',
        isPublic: true,
    },
    component: () => import(/* webpackChunkName: "monitor" */ '../views/MonitorOutboundCompletedStats.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'oidc'));

router.beforeEach((to, from, next) => {
  if (to.meta.isGranted !== undefined && to.meta.isGranted === false) {
    Vue.prototype.$flashStorage.flash('Not authorized', 'error', {timeout: 10000});
    next({ name: 'dashboard' })
  } else {
    next()
  }
})

export default router
